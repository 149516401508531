import React, { useState, useEffect } from 'react';
import QueueService from './QueueService';
import './AllEnteredPage.css';
import { FaEdit } from 'react-icons/fa'; 
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;

const AllEnteredPage = () => {
  const [customers, setCustomers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [exitTime, setExitTime] = useState('');
  const [editValue, setEditValue] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedRange, setSelectedRange] = useState('today'); // Default to today
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [tempValue, setTempValue] = useState('');



  const startEditing = (index, field, currentValue) => {
    setEditingIndex(index);
  };


  const handleCancel = () => {
    setEditingIndex(null);
    setTempValue('');
  };

  const downloadCSV = () => {
    // Define the headers of the CSV
    const headers = [
      "S.No",
      "Name",
      "Phone",
      "People",
      "Table Seating",
      "Status",
      "Table No",
      "Initial Waiting Number",
      "Total Waiting Time(mins)",
      "Arrived At",
      "Entry Time",
      "Delete Time",
      "Exit Time",
      "Notes",
      "Deleted Reason"
    ];
  
    // Prepare the CSV content
    const csvContent = customers.map((customer, index) => {
      const notesText = customer.notes
        ? customer.notes.map(note => `${formatNoteTime(note.note_created_at)}: ${note.note_text}`).join(" | ")
        : "";
  
      const totalWaitingTime = Math.floor(
        (new Date(customer.entry_time) - new Date(customer.created_at)) / 1000 / 60
      );
  
      return [
        index + 1,
        customer.name,
        customer.phone,
        customer.no_of_people,
        customer.queue_name,
        customer.status,
        customer.table_no || "",
        Math.round(customer.initial_waiting_number) || "",
        totalWaitingTime || "",
       `"${formatDateTime(customer.created_at)}"`,
      `"${formatDateTime(customer.entry_time)}"`,
      `"${formatDateTime(customer.delete_time)}"`,
        customer.exit_time ? `"${formatDateTime(customer.exit_time)}"`: "",
        notesText,
        customer.removed_reason || ""
      ].join(",");
    });
    const csvData = [headers.join(","), ...csvContent].join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "customers_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };


  const getEpochTimeRange = (rangeType) => {
    const now = new Date();
    let startTime, endTime;
  
    if (rangeType === 'today') {
      startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000;
      endTime = Math.floor(now.getTime() / 1000);
    } else if (rangeType === 'yesterday') {
      const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      startTime = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()).getTime() / 1000;
      endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000;
    } else if (rangeType === 'custom') {
      startTime = new Date(customStartDate).getTime() / 1000;
      endTime = new Date(customEndDate).getTime() / 1000;
    }
  
    return { startTime, endTime };
  };



  const fetchCustomers = () => {
    const { startTime, endTime } = getEpochTimeRange(selectedRange);
    
    // Construct query parameters
    const params = {
      start_time: startTime,
      end_time: endTime
    };
    
    QueueService.CustomerInfo(params)
      .then(response => {
        if (response) {
          setCustomers(response);
        } else {
          console.error('Failed to fetch customer info:', response.errors);
        }
      })
      .catch(console.error);
  }; 

  useEffect(() => {
    fetchCustomers(); // Fetch customers on initial load and whenever selectedRange changes
  }, [selectedRange, customStartDate, customEndDate]); // Adding dependencies ensures the effect runs when these values change

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    
    const date = new Date(dateTimeString);
    
    // Convert the UTC time to IST by adding 5 hours and 30 minutes
    const offsetInMinutes = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
    const istDate = new Date(date.getTime() + offsetInMinutes * 60000);
  
    return istDate.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit'
    });
  };



  const handleExitTimeChange = (event, index) => {
    setExitTime(event.target.value);
    setEditingIndex(index);
};



  const formatExitTimeForEditing = (exitTime) => {
    if (!exitTime) return '';
    
    const utcDate = new Date(exitTime);
    const offsetInMinutes = 5 * 60 + 30;
    const istDate = new Date(utcDate.getTime() + offsetInMinutes * 60000);
    
    return istDate.toISOString().slice(0, 16);
};




const handleExitTimeBlur = (customer) => {
  if (exitTime) {
      const istDate = new Date(exitTime);
      const offsetInMinutes = 5 * 60 + 30;
      const utcDate = new Date(istDate.getTime());
      const exitTimeUnix = Math.floor(utcDate.getTime() / 1000);

      const updatedCustomers = customers.map((c, idx) => {
          if (idx === editingIndex) {
              return { ...c, exit_time: utcDate.toISOString() };
          }
          return c;
      });
      setCustomers(updatedCustomers);
      setEditingIndex(null);

      const data = {
          mapping_id: customer.mapping_id,
          exit_time: exitTimeUnix
      };

      fetch(`${BASE_URL}/update_mapping`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
          console.log('Exit time updated successfully:', data);
      })
      .catch(error => {
          console.error('Error updating exit time:', error);
      });
  }
};

const formatNoteTime = (noteCreatedAt) => {
  const utcDate = new Date(noteCreatedAt);
  const offsetInMinutes = 5 * 60 + 30;
  const istDate = new Date(utcDate.getTime() + offsetInMinutes * 60000);
  
  return istDate.toLocaleTimeString('en-IN', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
  });
};

  const handleEditChange = (event, index, column) => {
  setEditValue(event.target.value);
  setEditingIndex(index);
  setSelectedColumn(column);
};

const handleSubmit = async (customer, field, newStatus) => {
  console.log('New status:', tempValue);
  try {
    let apiUrl;
    let apiPayload;

    if (field === 'status') {
      if (newStatus === 'entered') {
        // When changing status to 'entered', call the `update_mapping` API with `mark_as_active: true`
        apiUrl = `${BASE_URL}/update_mapping`;
        apiPayload = {
          mapping_id: customer.mapping_id,
          mark_as_entered: true,
        };
      } else if (newStatus === 'active') {
        // When changing status to 'active', call the `add_or_update_user` API with customer as the payload
        console.log('in add or update user');
        console.log(customer);
        apiUrl = `${BASE_URL}/add_or_update_user`;
        apiPayload = {
          // Include the relevant customer data as the payload
          ...customer,
        };
      }
    }

    // Call the respective API
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(apiPayload),
    });

    const data = await response.json();
    if (response.ok) {
      console.log(`${field} updated successfully:`, data);
    } else {
      console.error(`Error updating ${field}:`, data);
    }

    // Update the UI after successful API call
    const updatedCustomers = customers.map((c, idx) => {
      if (idx === editingIndex) {
        return { ...c, status: tempValue };
      }
      return c;
    });

    setCustomers(updatedCustomers);
    setEditingIndex(null);
    setTempValue('');
  } catch (error) {
    console.error(`Error updating ${field}:`, error);
  }
};

const handleStatusChange = (customer, newStatus) => {
  console.log('here');
  console.log('New status:', newStatus);
  
  setTempValue(newStatus);
  handleSubmit(customer, 'status', newStatus );
};



// const handleStatusChange = (customer, newStatusColumn) => {
//   const updatedCustomers = customers.map((c, idx) => {
//     if (idx === editingStatusIndex) {
//       return { ...c, status: newStatusColumn === 'mark_as_entered' ? 'entered' : 'active' };
//     }
//     return c;
//   });
//   setCustomers(updatedCustomers);
//   setEditingStatusIndex(null);

//   const data = {
//     mapping_id: customer.mapping_id,
//     [newStatusColumn]: true,
//   };

//   fetch(`${BASE_URL}/update_mapping?auth_token=${token}`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     credentials: 'include',
//     body: JSON.stringify(data),
//   })
//     .then(response => response.json())
//     .then(data => {
//       console.log('Status updated successfully:', data);
//     })
//     .catch(error => {
//       console.error('Error updating status:', error);
//     });
// };



const handleEditBlur = (customer) => {
  if (editValue) {
    const updatedCustomers = customers.map((c, idx) => {
      if (idx === editingIndex) {
        return { ...c, [selectedColumn]: editValue };
      }
      return c;
    });
    setCustomers(updatedCustomers);
    setEditingIndex(null);

    const data = {
      mapping_id: customer.mapping_id,
      [selectedColumn]: editValue
    };

    fetch(`${BASE_URL}/update_mapping`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        console.log(`${selectedColumn} updated successfully:`, data);
      })
      .catch(error => {
        console.error(`Error updating ${selectedColumn}:`, error);
      });
  }
};
const formatColumnContent = (customer, column) => {
  if (column === 'exit_time') {
    return customer.exit_time ? formatDateTime(customer.exit_time) : '';
  }
  return customer[column] || 'NA';
};

  return (
    <div className="all-entered-page">
      <h1>All Customers</h1>

      {/* Filter Section */}
      <div className="filter-buttons">
        <button onClick={() => setSelectedRange('today')}>Today</button>
        <button onClick={() => setSelectedRange('yesterday')}>Yesterday</button>
        <button onClick={() => setSelectedRange('custom')}>Custom</button>
      </div>

      {selectedRange === 'custom' && (
        <div className="custom-date-picker">
          <input 
            type="date" 
            value={customStartDate} 
            onChange={(e) => setCustomStartDate(e.target.value)} 
          />
          <input 
            type="date" 
            value={customEndDate} 
            onChange={(e) => setCustomEndDate(e.target.value)} 
          />
          <button onClick={() => fetchCustomers()}>Apply</button>
        </div>
      )}

        <div className="download-button-container">
          <button onClick={downloadCSV} className="download-csv-button">
            Download CSV
          </button>
        </div>

      <table className="customers-table">
        <thead>
          <tr>
            <th>S.No</th> 
            <th>Name</th>
            <th>Phone</th>
            <th>People</th>
            <th>Table Seating</th>
            <th>Status <FaEdit /></th>
            <th>Table No <FaEdit /></th>
            <th>Initial Waiting Number</th>
            <th>Total Waiting Time(mins)</th>
            <th>Arrived At</th>
            <th>Entry Time</th>
            <th>Delete Time</th>
            <th>Exit Time <FaEdit /></th>
            <th>Notes</th>
            <th>Deleted Reason <FaEdit /></th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{customer.name}</td>
              <td>{customer.phone}</td>
              <td>{customer.no_of_people}</td>
              <td>{customer.queue_name}</td>
              <td>
              {index === editingIndex && selectedColumn === 'status' ? (
                <>
                  {customer.status === 'inactive' && (
                    <>
                      <button onClick={() => handleStatusChange(customer, 'active')}>Mark Active in Queue</button>
                      <button onClick={() => handleStatusChange(customer, 'entered')}>Mark as Entered</button>
                    </>
                  )}
                  {customer.status === 'entered' && (
                    <button onClick={() => handleStatusChange(customer, 'active')}>Mark Active in Queue</button>
                  )}
                  {customer.status === 'active' && (
                    <button onClick={() => handleStatusChange(customer, 'entered')}>Mark as Entered</button>
                  )}
                  <button onClick={handleCancel}>Cancel</button>
                </>
              ) : (
                <span
                  onClick={() => {
                    
                    startEditing(index, 'status', customer.status);
                    setSelectedColumn('status');
                  }}
                  className="editable"
                >
                  {customer.status}
                </span>
              )}
            </td>
              <td>
                {customer.status === 'entered' && index === editingIndex && selectedColumn === 'table_no' ? (
                  <input
                    type="text"
                    value={editValue}
                    onChange={(event) => handleEditChange(event, index, 'table_no')}
                    onBlur={() => handleEditBlur(customer)}
                  />
                ) : (
                  <span
                    onClick={() => {
                      setEditingIndex(index);
                      setSelectedColumn('table_no');
                      setEditValue(customer.table_no || '');
                    }}
                    className="editable"
                  >
                    {formatColumnContent(customer, 'table_no')}
                  </span>
                )}
              </td>
              <td>{customer.initial_waiting_number ? Math.round(customer.initial_waiting_number) : ''}</td>
              <td>
            {Math.max(0, Math.floor((new Date(customer.entry_time) - new Date(customer.created_at)) / 1000 / 60))}
          </td>
            <td>{formatDateTime(customer.created_at)}</td>
              <td>{formatDateTime(customer.entry_time)}</td>
              <td>{formatDateTime(customer.delete_time)}</td>
              <td>
              {customer.status === 'entered' && index === editingIndex ? (
                  <input
                      type="datetime-local"
                      value={exitTime}
                      onChange={(event) => handleExitTimeChange(event, index)}
                      onBlur={() => handleExitTimeBlur(customer)}
                      className="exit-time-input"
                  />
              ) : (
                  <span
                      onClick={() => {
                          if (customer.status === 'entered') {
                              setEditingIndex(index);
                              setExitTime(customer.exit_time ? formatExitTimeForEditing(customer.exit_time) : '');
                          }
                      }}
                      className={customer.status === 'entered' ? 'editable' : ''}
                  >
                      {customer.exit_time ? formatDateTime(customer.exit_time) : 'NA'}
                  </span>
              )}
          </td>
          
          <td>
    {customer.notes && customer.notes.length > 0 && (
        customer.notes.map((note, idx) => (
            <div key={note.note_id}>
                {formatNoteTime(note.note_created_at)}: {note.note_text}
                {idx < customer.notes.length - 1 && ' | '}
            </div>
        ))
    )}
</td>
<td>{customer.removed_reason}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllEnteredPage;
