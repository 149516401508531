import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserForm from './UserForm';
import './QrCodePage.css';
import './CustomerTrackingPage.css';

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;

const QrPage = () => {
  const location = useLocation();
  const [businessId, setBusinessId] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [businessMetadata, setBusinessMetadata] = useState(null);
  const [enteredUser, setEnteredUser] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [queueInfo, setQueueInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerName, setCustomerName] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid');

    if (uuid) {
      const fetchBusinessInfo = async () => {
        try {
          const response = await fetch(`${BASE_URL}/validate_qr?uuid=${uuid}`);
          const result = await response.json();
          if (result.data) {
            setBusinessId(result.data.business_id); // Set the businessId from the top-level response
            setBusinessInfo(result.data.queue_info[0]);
            setBusinessMetadata(result.data.business_metadata);
            setQueueInfo(result.data.queue_info);
            setBusinessName(result.data.business_name);
          } else {
            console.error('Invalid response from validate API:', result);
          }
        } catch (error) {
          console.error('Error fetching business information:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchBusinessInfo();
    } else {
      setLoading(false); // Set loading to false if uuid is not present
    }
  }, [location]);

  const handleSubmit = async (formData) => {
    const dataToSubmit = {
      ...formData,
      business_id: businessId, // Use the businessId from the state
    };

    console.log('Form submitted:', dataToSubmit);

    try {
      const response = await fetch(`${BASE_URL}/enqueue_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('User enqueued successfully:', result);

        if (result.success) {
          if (result.data.queue_info[0].status === 'active') {
            window.location.href = result.data.unique_url;
          } else if (result.data.queue_info[0].status === 'entered') {
            setCustomerName(result.data.queue_info[0].customer_name);
            setEnteredUser(true);
          }
        }
      } else {
        console.error('Error enqueuing user:', response.statusText);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  if (loading) {
    return <div className="loader"></div>; // Show loader while fetching data
  }
if (enteredUser) {
  return (
    <div className="customer-tracking-page">
      {businessInfo && (
         <div className="top-bar">
         <img src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" alt="Waitwhiz Logo" className="waitwhiz-logo" />
         <img src={businessInfo.business_metadata.logo_url} alt="Business Logo" className="business-logo" />
       </div>
      )}
      <div className="circle-info" style={{ textAlign: 'center' }}>
        <p>Hi {customerName},</p>
        <p>Welcome to {businessName}, We wish you a nice experience!</p>
        <p>
          For Menu, <a href={businessMetadata.menu_url} target="_blank" rel="noopener noreferrer">click here</a>.
        </p>
      </div>
    </div>
  );
}

  return (
    <div>
       {businessMetadata && (
  <div>
     <div className="top-bar">
    <img src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" alt="Waitwhiz Logo" className="waitwhiz-logo" />
    <img src={businessMetadata.logo_url} alt="Business Logo" className="business-logo" />
  </div>

    <div style={{ textAlign: 'center', flex: 1 }}>
      <p>
        For Menu,{' '}
        <a
          href={businessMetadata.menu_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          click here
        </a>
        .
      </p>
      <h3>Please fill in the form for entry</h3>
    </div>
  </div>
)}
      {businessInfo ? (
        <div style={{ padding: '0 20px' }}>
          <UserForm
            onSubmit={handleSubmit}
            queue_info={queueInfo}
            auto_queue={businessMetadata.automatic_queue_selection}
            usercheck={true}
            isPhoneRequired={true}
            business_id={businessId}
          />
          {queueInfo && (
            <div className="disclaimer">
              {queueInfo.map((queue, index) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                  <h4>{queue.queue_name}</h4>
                  {queue.max_waiting_number <= 1 ? (
                    <p>There is no waiting going on.</p>
                  ) : (
                    <>
                      <p>
                        Estimated Wait time:&nbsp;
                        <strong>
                          {queue.estimated_wait_time_buffer === 0
                            ? `${queue.total_initial_waiting_time}`
                            : `${queue.total_initial_waiting_time}-${queue.total_initial_waiting_time + queue.estimated_wait_time_buffer}`}
                        </strong>
                        &nbsp;minutes.
                      </p>
                      <p>People already in the queue: <strong>{queue.max_waiting_number - 1}</strong></p>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          {businessMetadata.dos_and_donts && (
            <div className="DosAndDonts">
              <p>
              {businessMetadata.dos_and_donts}
              </p>
              </div>
          )
          }

        </div>
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
};

export default QrPage;
