
import React from 'react';
import './EntryScreen.css';
import checkIcon from '../assets/Check.svg';

const EntryScreen = ({business_info, customer_info}) => {

return (
	<div className="iphone-14-15-pro-11">
	<div className="frame-parent">
	<div className="waitwhiz-logo-pure-white-backg-parent">
	<img className="waitwhiz-logo-pure-white-backg-icon" alt="" src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" />
	<img className="comorin-logo-1" alt="" src={business_info.business_metadata.logo_url} />
	</div>
	<div className="frame-group">
	<div className="hi-harman-parent">
	<b className="hi-harman">Hi {customer_info.customer_name},</b>
	<b className="hi-harman">Welcome to {business_info.business_name}</b>
	</div>
	<div className="ellipse-parent">
	<div className="frame-child" />
	<img className="check-icon" alt="" src={checkIcon} />
	</div>
	<div className="for-menu-click-here-parent">
	<div className="hi-harman">
	<span>
	<b>For Menu</b>
	<span className="span">{`, `}</span>
	</span>
	<a
							className="click-here"
							href={business_info.business_metadata.menu_url}
							target="_blank"
							rel="noopener noreferrer"
						>
							<b>Click Here</b>
						</a>
	</div>
	<b className="hi-harman">We wish you a wonderful experience</b>
	</div>
	</div>
	</div>
	</div>);


};

export default EntryScreen;

