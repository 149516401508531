import React from 'react';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import './NumberModal.css';

const NumberModal = ({ waiting_time, max_waiting_number, token, queue_id, handleNumberChange, handleNumberSubmit, onClose }) => {
  const handleSubmit = () => {
    handleNumberSubmit(queue_id, token, waiting_time/max_waiting_number);
  };

  return (
    <div className="number-modal-content">
      <button onClick={() => handleNumberChange(waiting_time - 1,max_waiting_number,queue_id)} className="number-change-button">
        <FaMinusCircle />
      </button>
      <input
        type="number"
        value={waiting_time}
        onChange={(e) => handleNumberChange(Number(e.target.value), max_waiting_number,queue_id)}
        className="number-input"
      />
      <button onClick={() => handleNumberChange(waiting_time + 1, max_waiting_number,queue_id)} className="number-change-button">
        <FaPlusCircle />
      </button>
      <button onClick={handleSubmit} className="submit-button">
        Submit
      </button>
    </div>
  );
};

export default NumberModal;