import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import UserForm from './UserForm';
import ReactDOM from 'react-dom'; 
import ConfirmEntryModal from './ConfirmEntryModal';
import AddRemarkModal from './AddRemarkModal';
import QueueService from './QueueService';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { FaEllipsisV, FaPlus, FaArrowRight, FaToggleOn, FaToggleOff, FaPhoneAlt} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './QueueManager.css';
import Message from "./Message";
import {onMessage } from 'firebase/messaging'
import { messaging } from "../firebase/firebaseConfig";
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import NumberModal from './NumberModal'; 
import CallModal from './CallModal'; // 

const QueueManager = ({ business_id, business_user_id, token, handleLogout }) => {
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [businessImage, setBusinessImage] = useState('');
  const [modalData, setModalData] = useState({
    waiting_time: 0,
    max_waiting_number: 0,
    queue_id: null,
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [queue_info, setQueueInfo] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentQueue, setCurrentQueue] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
const [showCallModal, setCallModal] = useState({
  isOpen: false,
  userId: null,
  userName: null
});
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [queue_auto, setQueueAutoSelect] = useState(false);
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const [entryUserId, setEntryUserId] = useState(null);
  const [remarkUserId, setRemarkUserId] = useState(null);
  const [message, setMessage] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showNumberModal, setShowNumberModal] = useState(false);
  const dropdownRef = React.useRef(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  let counter = 0;
  onMessage(messaging, (payload) => {
    counter = counter + 1;
    console.log(payload);
    console.log(payload.title);
    if (payload.notification.title === 'user_enqueued'){
      updateNotificationPanel([{"body": `${counter} new User(s) added`}]);
    }
    else if (payload.notification.title === 'call_status') {
      updateUserCallStatus(JSON.parse(payload.notification.body));
    }
    else if(payload.notification.title === 'mapping_updated') {
      const body = JSON.parse(payload.notification.body)
      if(body.status === 'entered') {
        updateNotificationPanel([{"body": `User(s) marked as Entered`}]);
    }
    else if(body.status === 'inactive'){
      updateNotificationPanel([{"body": `User(s) Deleted`}]);
    }
    else if(body.table_ready === true) {
      updateUser(body)
    }
  }
  });


  function updateUserCallStatus(newCallData) {
    setUsers(prevUsers =>
      prevUsers.map(user => {
        if (user.id === newCallData.entity_id) {
          const updatedCalls = user.calls.map(call => 
            call.id === newCallData.id ? { ...call, ...newCallData } : call
          );
  
          const callExists = updatedCalls.some(call => call.id === newCallData.id);
  
          const newCalls = callExists 
            ? updatedCalls 
            : [newCallData, ...updatedCalls];
          
          const newUser = {
            ...user,
            calls: newCalls
          };
          return newUser;
        }
        return user;
      })
    );
  }

  function updateUser(newUserData) {
    setUsers(prevUsers =>
      prevUsers.map(user =>
        user.id === newUserData.id
          ? { ...user, ...newUserData }  // Merge the new user data with the existing user object
          : user  // Keep the other users as they are
      )
    );
  }
  

  function updateNotificationPanel(notifications) {
    const notificationPanel = document.getElementById('notification-panel');
  
    if (notifications.length === 0) {
      notificationPanel.style.display = 'none';
    } else {
      notificationPanel.style.display = 'block';
    }
  
    notificationPanel.innerHTML = ''; // Clear existing notifications
  
    notifications.forEach((notification, index) => {
      const container = document.createElement('div');
      container.setAttribute('id', `notification-${index}`);
      
      // Create a root for each notification
      const root = ReactDOM.createRoot(container);
  
      root.render(
        <Message
          notification={notification}
          onClick={() => {
            fetchAllUsers(business_id, business_user_id);
            root.unmount(); // Properly unmount the component
            notificationPanel.removeChild(container); // Remove the container from DOM
          }}
        />
      );
  
      notificationPanel.appendChild(container);
    });
  }
  
  const fetchBusinessInfo = (business_id) => {
    QueueService.GetBusinessInfo(business_id)
      .then(response => {
        if (response.queue_info && response.queue_info.length > 0) {
          setQueueInfo(response.queue_info);
          setBusinessImage(response.business_metadata.logo_url);
          setQueueAutoSelect(response.business_metadata.automatic_queue_selection);
        }
      })
      .catch(console.error);
  };


  const fetchAllUsers = (business_id, business_user_id) => {
    QueueService.FetchUsers(business_id, business_user_id, 'All', token)
      .then(users => {
        const updatedUsers = users.map(user => ({
          ...user
          // ,
          // remainingTime: calculateRemainingTime(user.tentative_entry_time)
        }));
        setUsers(updatedUsers);
      })
      .catch(console.error);
      
  };
  

  useEffect(() => {
    
    const savedQueue = parseInt(localStorage.getItem('currentQueue'), 10);
    console.log(savedQueue);
    if (savedQueue) {
      setCurrentQueue(savedQueue); // Set from localStorage if it exists
    } else if (queue_info.length > 0) {
      setCurrentQueue(queue_info[0].queue_id); // Initialize only on first load if nothing is in localStorage
    }
  }, [queue_info]);
  


  useEffect(() => {
    fetchAllUsers(business_id, business_user_id);
    fetchBusinessInfo(business_id);
}, [business_id, business_user_id]);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setUsers(users => users.map(user => ({
  //       ...user
  //       // ,
  //       // remainingTime: calculateRemainingTime(user.tentative_entry_time)
  //     })));
  //   }, 60000); // Update every minute

  //   return () => clearInterval(interval); // Clear interval on component unmount
  // }, [users]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownIndex(null);
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const calculateRemainingTime = (epoch) => {
  //   const now = Date.now();
  //   const waitingTimeInMillis = epoch * 1000; // Convert seconds to milliseconds
  //   const endTime = waitingTimeInMillis;
  //   const remainingTime = Math.max(0, Math.floor((endTime - now) / 60000)); // Convert milliseconds to minutes
  //   return remainingTime;
  // };


  const handleCellClick = async (user) => {
    console.log(user);
  };
  const handleToggleStatus = async (queue_id, status) => {
    try {
      const newStatus = status === 'active' ? 'false' : 'true';
      const res = await QueueService.UpdateQueueStatus(token, queue_id, newStatus);
      setMessage({ type: 'success', text: res.message || 'Queue status updated successfully!' });
     
      const response = await QueueService.GetBusinessInfo(business_id);
      
      if (response.queue_info && response.queue_info.length > 0) {
        setQueueInfo(response.queue_info);
        setBusinessImage(response.business_metadata.logo_url);
      }
    } catch (error) {
      console.error(error);
      setMessage({ type: 'error', text: error.message || 'Failed to update queue status.' });

    }
    finally {
      setTimeout(() => setMessage(null), 3000);
    }

  };
  

  const handleAllEntered = () => {
    window.open('/all-entered', '_blank'); // Replace '/all-entered' with the actual path
  };
  
  const handleNumberClick = (waiting_time, max_waiting_number, queue_id) => {
    setModalData({
      waiting_time,
      max_waiting_number,
      queue_id,
    });
    setShowNumberModal(true);
  };
  
  const handleNumberChange = (waiting_time, max_waiting_number, queue_id) => {
    setModalData({
      waiting_time,
      max_waiting_number,
      queue_id,
    });
  };
  
  const handleNumberSubmit = async (currentQueue, token, waiting_time) => {
    try {
      await QueueService.UpdateQueueNumber(token, currentQueue, waiting_time); // Call API to update the number
      setShowNumberModal(false);
      
    } catch (error) {
      console.error('Error updating number:', error);
    }
    fetchAllUsers(business_id, business_user_id);
    fetchBusinessInfo(business_id);
    
  };
  
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    
    const date = new Date(dateTimeString);
    
    // Convert the UTC time to IST by adding 5 hours and 30 minutes
    const offsetInMinutes = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
    const istDate = new Date(date.getTime() + offsetInMinutes * 60000);
  
    return istDate.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const makeIVRCall = async (user_id, user_name) => {
    setCallModal({
      isOpen: true,
      userId: user_id,
      userName: user_name
    });
     
  }
  
  const confirmCall = async (user_id, user_name) => {
    try{
    const res = await QueueService.makeCall(user_id, token); 
    // updateUserCallStatus(res);
    setMessage({ type: 'success', text: `Calling ${user_name}`});
    setTimeout(() => setMessage(null), 3000);
    }
    catch (error) {
      console.error(error);
      setMessage({ type: 'error', text: error.message || 'Failed to update queue status.' });
      setTimeout(() => setMessage(null), 3000);

  }
  setCallModal({
    isOpen: false,
    userId: null,
    userName: null
  });
}



  const handleAddUser = () => {
    setCurrentUser(null);
    setShowForm(true);
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    setShowForm(true);
    setOpenDropdownIndex(null);
  };

  const handleUserEntry = (userId) => {
    setEntryUserId(userId);
    setShowEntryModal(true);
  };

  const add_note = (userId) => {
    setRemarkUserId(userId);
    setShowRemarkModal(true);
    setOpenDropdownIndex(null);
  };


  
  const confirmUserEntry = async (tableNo, entryTime) => {
    try {
      await QueueService.MarkUserAsEntered(entryUserId, token, tableNo, entryTime );
      setUsers(users.filter(user => user.id !== entryUserId));
      setShowEntryModal(false);
      setEntryUserId(null);
    } catch (error) {
      console.error('Error marking user as entered:', error);
    }
    fetchAllUsers(business_id, business_user_id);
    fetchBusinessInfo(business_id);
    
  };


    const confirmAddRemark = async (remark) => {
      try {
        await QueueService.add_note(token, remarkUserId, remark );
        setUsers(users.filter(user => user.id !== remarkUserId));
        setShowRemarkModal(false);
        setRemarkUserId(null);
      } catch (error) {
        console.error('Error marking user as entered:', error);
      }
      fetchAllUsers(business_id, business_user_id);
  };

  const handleDeleteUser = (userId) => {
    setSelectedUser(userId);
    setShowDeleteModal(true);
    setOpenDropdownIndex(null);
  };

  const confirmDeleteUser = async (reason) => {
    try {
      await QueueService.DeleteUser(selectedUser, reason, business_id);
      setUsers(users.filter(user => user.id !== selectedUser));
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
    fetchAllUsers(business_id, business_user_id);
    fetchBusinessInfo(business_id);
  };


  const handleSetCurrentQueue = (queueId) => {
    
    setCurrentQueue(queueId);
    localStorage.setItem('currentQueue', queueId);
  };

  useEffect(() => {
    const filteredUsers = users.filter(user => user.queue_id === currentQueue);
    setFilteredUsers(filteredUsers);
}, [users, currentQueue]);



  const handleFormSubmit = async (user) => {
    const userPayload = {
      ...user,
      business_id,
    };
    if (currentUser) {
      try {
        const updatedUser = await QueueService.EditUser(userPayload, token);
        setUsers(users.map(u => (u.id === currentUser.id ? updatedUser : u)));
      } catch (error) {
        console.error('Error editing user:', error);
      }
    } else {
      try {
        const newUser = await QueueService.AddUser(userPayload, token);
        setUsers([...users, { id: users.length + 1, ...newUser }]);
      } catch (error) {
        console.error('Error adding user:', error);
      }
    }

    setShowForm(false);
    fetchAllUsers(business_id, business_user_id);
    fetchBusinessInfo(business_id);
  };
  


  return(<div className="queue-manager">
  <div className="header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <img src={businessImage} alt="Business Logo" className="business-image" />

    <div className="user-details" style={{ display: 'flex', alignItems: 'center' }}>

      <button 
      onClick={() => {
        window.open('/qr-code', '_blank');
      }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          marginRight: '20px' // Add some space between the elements
        }}
      >
        <FontAwesomeIcon 
          icon={faQrcode} 
          size="lg" 
          style={{ color: 'black' }} 
        />
      </button>
      <div className="logout-dropdown" style={{ position: 'relative' }}>
        <div className="logout-icon" onClick={() => toggleDropdown('logout')}>
          <FaEllipsisV size={20} />
        </div>
        {openDropdownIndex === 'logout' && (
          <div className="logout-dropdown-menu" ref={dropdownRef} style={{ position: 'absolute', right: 0 }}>
            <button onClick={handleAllEntered} className="dropdown-item" >Customers</button>
            <button onClick={handleLogout} className="dropdown-item">
              Logout
            </button>
            
          </div>
        )}
      </div>
    </div>
  
</div>

      {showForm && ( <Modal onClose={() => setShowForm(false)}> <UserForm onSubmit={handleFormSubmit} initialData={currentUser} queue_info={queue_info} auto_queue={queue_auto}usercheck={false} isPhoneRequired={false} business_id={business_id}/> </Modal> )}
      {showDeleteModal && ( <ConfirmDeleteModal onClose={() => setShowDeleteModal(false)} onConfirm={confirmDeleteUser} userType="admin" /> )}
      {showEntryModal && ( <ConfirmEntryModal onClose={() => setShowEntryModal(false)} onConfirm={confirmUserEntry} /> )}
      {showRemarkModal && ( <AddRemarkModal onClose={() => setShowRemarkModal(false)} onConfirm={confirmAddRemark} /> )}
      {showCallModal.isOpen && (
        <CallModal
          onClose={() => setCallModal({ isOpen: false, userId: null, userName: null})}
          onConfirm={() => confirmCall(showCallModal.userId, showCallModal.userName)}
        />
      )}
      {message && ( <div className={`message-box ${message.type}`}> {message.text} </div> )}


    <div id="notification-panel" class="notification-panel"></div>

  {queue_info.length > 0 && (
  <div className="queues">
    {queue_info.map((queue, index) => (
      <div key={index} className="queue-container">
        <div className="queue-header">
          <div
            onClick={() => handleNumberClick(queue.total_initial_waiting_time, queue.max_waiting_number, queue.queue_id)}
            className="progress-container"
          >
            <div className="progress-circle">
              <svg width="60" height="60" viewBox="0 0 36 36" className="circular-chart">
                <path
                  className="circle-bg"
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                  className="circle"
                  strokeDasharray={`${(queue.total_initial_waiting_time / 100) * 100}, 100`}
                  d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" className="percentage">
                  {queue.total_initial_waiting_time} min
                </text>
              </svg>
            </div>
          </div>
          <div
            className="toggle-icon"
            onClick={() => handleToggleStatus(queue.queue_id, queue.status)}
          >
            {queue.status === 'active' ? (
            <FaToggleOn style={{ color: 'green' }} />
          ) : (
            <FaToggleOff style={{ color: 'red' }} />
          )}
          </div>
        </div>
        <button
          className={`queue-button ${currentQueue === queue.queue_id ? 'active' : ''}`}
          
          onClick={() => handleSetCurrentQueue(queue.queue_id)}
        >
          {queue.queue_name} ({users.filter(user => user.queue_id === queue.queue_id).length})
        </button>
      </div>
    ))}
  </div>
)}

      <table className="queue-table">
        <thead>
          <tr>
            <th>Name (Pax)</th>
            <th>M. NO</th>
            <th>Arrival Time</th>
            <th className="wait-time-column" >Wait Time</th>
            <th>Mark Entry</th>
            <th>Actions</th>
            <th>Remarks</th>

          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user, index) => (
            <tr key={user.id}>
             
              <td>{user.customer_name} ({user.no_of_people})</td>
              <td>
                <a href={`tel:${user.phone_number}`} className="phone-link">
                  {user.phone_number}
                </a>
                {user.calls && user.calls.length > 0 && (
                <div className="call-info" style={{ marginTop: '4px' }}>
                  {user.calls.map((call, index) => (
                    <div key={index} className="call-details">
                      <div>
                        {formatDateTime(call.created_at)}{' '}
                        {call.answer_time !== null ? `${call.answer_time}s` : ''}
                      </div>
                      <div>
                        {/* Display icons based on the call status */}
                        {call.status === "ANSWERED" ? (
                          <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} />
                        ) : call.status === "FAILED" ? (
                          <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red' }} />
                        ) : call.status === null ? (
                          'calling...'
                        ) : (
                          call.status
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              </td>
              <td>{formatDateTime(user.created_at)}</td>
              <td
                onClick={user.table_ready ? null : () => makeIVRCall(user.id, user.customer_name)}
                style={{ cursor: user.table_ready ? 'default' : 'pointer' }} // Change cursor style to indicate it's non-clickable when invited
              >
                {user.table_ready ? (
                  <>
                    <span style={{ color: 'green' }}>INVITED</span>
                    <br />
                    {user.calls.map((call, index) => 
                    call.user_response === '1' ? (
                      <span key={index} style={{ color: 'blue', fontSize: '10px' }}>Coming</span>
                    ) : call.user_response === '2' ? (
                      <span key={index} style={{ color: 'red', fontSize: '10px' }}>Not Coming</span>
                    ) : null
                  )}
                  </>
                ) : (
                  <>
                    {user.estimated_wait_time_buffer === 0
                      ? `${user.initial_waiting_time} mins`
                      : `${user.initial_waiting_time}-${
                          user.initial_waiting_time + user.estimated_wait_time_buffer
                        } mins`}
                    <FaPhoneAlt className="call-icon" />
                  </>
                )}
              </td>
              <td onClick={() => handleUserEntry(user.id)}>
                {user.waiting_number}
                <FaArrowRight
                  className="mark-entry-icon"
                  style={{ cursor: 'pointer', marginLeft: '8px', color: "#007bff" }} // Optional styling
                />
              </td>
              <td>
                <button
                  className="action-button"
                  onClick={() => toggleDropdown(index)}
                >
                  <FaEllipsisV />
                </button>
                
                <div className="dropdown" style={{ position: 'relative' }}>
                  {openDropdownIndex === index && (
                    <div className="dropdown-menu" ref={dropdownRef} style={{ position: 'absolute', right: 0 }}>
                      <button onClick={() => handleEditUser(user)}>Edit</button>
                      <button onClick={() => add_note(user.id)}>Add Remark</button>
                      <button onClick={() => window.location.href = `https://wa.me/${user.phone_number}`}>WhatsApp</button>
                      <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
                    </div>
                  )}
                </div>
              </td>
              <td>
          {user.notes && user.notes.length > 0 && user.notes[0].note_text}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={handleAddUser} className="add-button">
        <FaPlus size={20} />
      </button>
      {showNumberModal && (
        <Modal onClose={() => setShowNumberModal(false)}>
          <NumberModal
            waiting_time={modalData.waiting_time}
            max_waiting_number={modalData.max_waiting_number}
            token={token}
            queue_id={modalData.queue_id}
            handleNumberChange={handleNumberChange}
            handleNumberSubmit={handleNumberSubmit}
            onClose={() => setShowNumberModal(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default QueueManager;
