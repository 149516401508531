import React, { useState, useEffect } from 'react';
import './Modal.css';

const UserForm = ({ onSubmit, initialData = true, queue_info, auto_queue, usercheck, isPhoneRequired, business_id }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    no_of_people: '',
    estimated_wait_time: '',
    queue_id: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submit button
  // Load saved form data from localStorage if usercheck is true
  useEffect(() => {
    if (usercheck) {
      const savedFormData = {
        name: localStorage.getItem('name') || '',
        phone: localStorage.getItem('phone') || '',
        no_of_people: localStorage.getItem('no_of_people') || ''
      };

      setFormData((prevFormData) => ({
        ...prevFormData,
        ...savedFormData
      }));
    }
  }, [usercheck]);

  // Populate form with initial data if provided

  useEffect(() => {
    if (business_id === 10 || business_id === 11) {
      setFormData(prevFormData => ({
        ...prevFormData,
        no_of_people: 1
      }));
    }
  }, [business_id]);

  
  useEffect(() => {
    if (initialData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: initialData.customer_name || prevFormData.name,
        phone: initialData.phone_number || prevFormData.phone,
        no_of_people: initialData.no_of_people || prevFormData.no_of_people,
        estimated_wait_time: initialData.initial_waiting_time,
        queue_id: initialData.queue_type
      }));
    }
  }, [initialData]);

  // Auto-select the queue ID if only one queue is available
  useEffect(() => {
    if (queue_info.length === 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        queue_id: queue_info[0].queue_id
      }));
    }
  }, [queue_info]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    
    if (['name', 'phone', 'no_of_people'].includes(name)) {
      localStorage.setItem(name, value);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Disable submit button
    const dataToSubmit = { ...formData };
    // Remove queue_id if it's empty or null
    if (!dataToSubmit.queue_id) {
      delete dataToSubmit.queue_id;
    }
  
    try {
      await onSubmit(dataToSubmit);
    } finally {
      setIsSubmitting(false); // Re-enable submit button after API response
    }
  };

  return (
    <form onSubmit={handleSubmit} className="user-form">
      <div className="form-group">
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <label>Phone:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          pattern="\d{10}"
          title="Phone number must be 10 digits long"
          placeholder="Enter 10-digit phone number"
          required={isPhoneRequired}
        />
      </div>

      {(business_id !== 10 && business_id !== 11) && (
  <div className="form-group">
    <label>No of People:</label>
    <input
      type="number"
      name="no_of_people"
      value={formData.no_of_people}
      onChange={handleChange}
      required
    />
  </div>
)}

      {/* Auto-selected queue ID field, hidden from the frontend */}
      {queue_info.length === 1 && (
        <input
          type="hidden"
          name="queue_id"
          value={formData.queue_id}
        />
      )}

      {/* Dropdown for table seating, visible only if there is more than one option */}
      {!auto_queue && (
        <div className="form-group">
          <label>Table Seating:</label>
          <select name="queue_id" value={formData.queue_id} onChange={handleChange} required>
            <option value="">Select Table Seating</option>
            {queue_info.map((queue) => (
              <option key={queue.queue_id} value={queue.queue_id}>
                {queue.queue_name}
              </option>
            ))}
          </select>
        </div>
      )}

      
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </form>
  );
};

export default UserForm;