import React, { useState, useEffect } from 'react';
import QueueManager from './components/QueueManager';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import QrPage from './components/Qrpage';
import QrCodePage from './components/QrCodePage'; 
import CustomerTrackingPage from './components/CustomerTrackingPage';
import AllEnteredPage from './components/AllEnteredPage';
import QueueService from './components/QueueService';
import './App.css';
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;
const { VITE_APP_VAPID_KEY } = "BC4SBQ1Uo9aB4CMPu93svGk4JLZTcsifaPUVL6ujDMVpLg6v80uOshaMfUXp1FeqSfKGp4_bHnJ8RYaKOu7pSoY";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [businessUserId, setBusinessUserId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedAuth = localStorage.getItem('authenticated') === 'true';
    const storedBusinessId = localStorage.getItem('businessId');
    const storedBusinessUserId = localStorage.getItem('businessUserId');
    const storedToken = localStorage.getItem('token');
    const storedQueueStarted = localStorage.getItem('queueStarted') === 'true';

    if (storedAuth && storedBusinessId && storedBusinessUserId && storedToken) {
      console.log('Stored authentication')
      setAuthenticated(true);
      setBusinessId(parseInt(storedBusinessId, 10));
      setBusinessUserId(parseInt(storedBusinessUserId, 10));
      setToken(storedToken);
    }
  }, []);


  useEffect(() => {
    if (businessId && token) {
      requestPermission();
    }
  }, [businessId, token]); 



  async function registerDevice() {
    try {
      const fcm_token = await getToken(messaging, {
        vapidKey: VITE_APP_VAPID_KEY,
      });
      const response = await fetch(`${BASE_URL}/register_device?auth_token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          entity_id: businessId,
          entity_type: 'business',
          fcm_token: fcm_token,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
  
    } catch (error) {
      console.error('Failed to register device:', error);
      return null;
    }
  }

  registerDevice()
  .then(data => {
    if (data) {
      console.log('Device registered successfully:', data);
    }
  });



  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log('Permission granted');
    } else if (permission === "denied") {
      console.log("You denied for the notification");
    }
  }

  const handleLogin = (business_id, business_user_id, token) => {
    setBusinessId(business_id);
    setBusinessUserId(business_user_id);
    setToken(token);
    setAuthenticated(true);
    localStorage.setItem('authenticated', 'true');
    localStorage.setItem('businessId', business_id.toString());
    localStorage.setItem('businessUserId', business_user_id.toString());
    localStorage.setItem('token', token);

  };

  const handleLogout = () => {
    setAuthenticated(false);
    setBusinessId(null);
    setBusinessUserId(null);
    setToken(null);
    localStorage.removeItem('authenticated');
    localStorage.removeItem('businessId');
    localStorage.removeItem('businessUserId');
    localStorage.removeItem('token');
    localStorage.removeItem('queueStarted');
    QueueService.Logout();

  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={!authenticated ? <Login onLogin={handleLogin} /> : <QueueManager 
              business_id={businessId} 
              business_user_id={businessUserId} 
              token={token}
              handleLogout={handleLogout} 
            />}
          />
          <Route path="/user_form" element={<QrPage token={token}/>} />
          <Route path="/qr-code" element={<QrCodePage token={token} business_id={businessId} />} />
          <Route path="/customer_tracking/:final_str" element={<CustomerTrackingPage />} />
          <Route path="/all-entered" element={<AllEnteredPage/>} />
        </Routes>
      </div>
      
    </Router>
  );
}
export default App;