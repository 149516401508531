import React, { useState, useEffect } from 'react';
import QueueService from './QueueService';
import './QrCodePage.css';

const QrCodePage = ({ token, business_id }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const fetchBusinessInfo = async () => {
      try {
        const response = await QueueService.GetBusinessInfo(business_id);
        setBusinessInfo({
          business_name: response.business_name,
          logo_url: response.business_metadata.logo_url,
          total_initial_waiting_time: response.queue_info[0].total_initial_waiting_time,
          max_waiting_number: response.queue_info[0].max_waiting_number,
          estimated_wait_time_buffer: response.queue_info[0].estimated_wait_time_buffer
        });
      } catch (error) {
        console.error('Error fetching business info:', error);
      } finally {
        setLoading(false); // Stop loading after fetching business info
      }
    };

    const fetchQRCode = async () => {
      try {
        const response = await QueueService.GetQRCode(token);
        setQrCodeUrl(response.qrCodeUrl);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };

    fetchBusinessInfo();
    fetchQRCode();
  }, [token, business_id]);

  return (
    <div className="qr-code-page">
      {loading ? (
        <div className="loader"></div> // Show loader while loading
      ) : (
        <>
          {businessInfo && (
            <div className="business-info">
              <img src={businessInfo.logo_url} alt="Business Logo" style={{ maxHeight: '100px' }} />
              <div className="welcome-text">
                <h2>Welcome to {businessInfo.business_name}</h2>
                <p>Please scan the code to get in the queue.</p>
              </div>
            </div>
          )}
          {qrCodeUrl && (
            <div className="qr-code-container">
              <img src={qrCodeUrl} alt="QR Code" className="qr-code-image" />
            </div>
          )}
          {businessInfo && (
            <div className="queue-info">
              <p>
            Estimated Wait time: 
            <strong>
              {businessInfo.estimated_wait_time_buffer === 0
                ? `${businessInfo.total_initial_waiting_time}`
                : `${businessInfo.total_initial_waiting_time}-${
                    businessInfo.total_initial_waiting_time + businessInfo.estimated_wait_time_buffer
                  }`}
            </strong> minutes.
          </p>
              <p>People already in the queue: <strong>{businessInfo.max_waiting_number - 1}</strong></p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QrCodePage;
