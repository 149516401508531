import React, { useState } from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';

const ConfirmEntryModal = ({ onClose, onConfirm }) => {
  const [tableNo, setTableNo] = useState('');

  const handleConfirm = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds (epoch)
    onConfirm(tableNo);
  };

  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
        <h2>Confirm Entry</h2>
        <p>Are you sure you want to mark this user as entered?</p>
        <div className="form-group">
          <label>Table No (optional):</label>
          <input
            type="number"
            value={tableNo}
            onChange={(e) => setTableNo(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="actions">
          <button onClick={onClose} className="btn cancel-button">Cancel</button>
          <button onClick={handleConfirm} className="btn confirm-button">Confirm</button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmEntryModal;