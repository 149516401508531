import React from 'react';
import './CancelledScreen.css';
import checkIcon from '../assets/close.svg';

const CancelledScreen= ({business_info, customer_info}) => {
  	// return (
    // 		<div className="iphone-14-15-pro-6">
    //   			<img className="waitwhiz-logo-pure-white-backg-icon" alt="" src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" />
    //   			<img className="comorin-logo-1" alt="" src={business_info.business_metadata.logo_url} />
    //   			<div className="iphone-14-15-pro-6-child" />
    //   			<b className="hi-harman">Hi {customer_info.customer_name},</b>
    //   			<b className="you-have-been">You have been removed from queue</b>
    //   			<b className="please-visit-reception">Please visit reception if still wish to dine with us</b>
    //   			<img className="close-icon" alt="" src={checkIcon} />
    //   			{/* <b className="call-comorin">Call Comorin</b> */}
    // 		</div>);


return (
	<div className="iphone-14-15-pro-12">
	<div className="frame-parent1">
	<div className="waitwhiz-logo-pure-white-backg-parent">
	<img className="waitwhiz-logo-pure-white-backg-icon" alt="" src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" />
	<img className="comorin-logo-1" alt="" src={business_info.business_metadata.logo_url} />
	</div>
	<div className="frame-group1">
	<div className="hi-harman-parent">
	<b className="hi-harman">Hi {customer_info.customer_name},</b>
	<b className="hi-harman">You have been removed from the queue</b>
	</div>
	<div className="ellipse-parent">
	<div className="frame-child1" />
	<img className="close-icon" alt="" src={checkIcon} />
	</div>
	<div className="please-visit-reception-if-stil-parent">
	<b className="hi-harman">Please visit reception if still wish to dine with us</b>
	{business_info.business_metadata.contact_info && (
				<a 
					className="call-comorin" 
					href={`tel:${business_info.business_metadata.contact_info}`}
				>
					Call {business_info.business_name}
				</a>
)}
	</div>
	</div>
	</div>
	</div>);

};

export default CancelledScreen;
