import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import QueueService from './QueueService'; // Assuming QueueService is imported correctly
import './CustomerTrackingPage.css'; // Import the CSS file
import { FaKey, FaUserFriends, FaClock, FaBox } from 'react-icons/fa';
import EntryScreen from './EntryScreen.js';
import WaitingScreen from './WaitingScreen.js';
import CancelledScreen from './CancalledScreen.js';
import InvitedScreen from './InvitedScreen.js';
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;

const CustomerTrackingPage = () => {
  const { final_str } = useParams(); // Get final_str from URL params
  const [queueInfo, setQueueInfo] = useState(null);
  const [tableready, setTableReady] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [deleteMessage, setDeleteMessage] = useState(''); // State for deletion confirmation message
  const [loading, setLoading] = useState(false); // Loading state


  useEffect(() => {
    console.log("final_str calling service");
    const fetchQueueInfo = async () => {
      setLoading(true); // Start loading
      console.log("Inside fetching queue info")
      try {
        const response = await fetch(`${BASE_URL}/queue_info?encoded_str=${final_str}`);
        const result = await response.json();

        if (result.success && result.data) {
          setQueueInfo(result.data[0]);
          setTableReady(result.data[0].table_ready);
          const businessResponse = await fetch(`${BASE_URL}/business_info?business_id=${result.data[0].business_id}`);
          const businessResult = await businessResponse.json();
          if (businessResult.success && businessResult.data) {
            setBusinessInfo(businessResult.data);
          } else {
            console.error('Invalid response from business_info API:', businessResult);
          }
        } else {
          console.error('Invalid response from queue_info API:', result);
        }
      } catch (error) {
        console.error('Error fetching queue information:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchQueueInfo();
  }, [final_str]);

  const handleDelete = async (reason) => {
    if (queueInfo) {
      try {
        const response = await QueueService.DeleteUser(queueInfo.id, reason, queueInfo.business_id);
        setQueueInfo(response.data);
        window.location.reload(); // Optionally clear the queue info if needed
      } catch (error) {
        console.error('Error deleting user:', error);
      } finally {
        setShowModal(false); // Close the modal after the operation
      }
    }
  };


  const renderTableReady = () => {
    console.log('table ready');
    return <InvitedScreen business_info={businessInfo} customer_info={queueInfo} setShowModal={setShowModal} />
  };

  const renderQueueInfo = () => {
    if (!queueInfo || !queueInfo.status) {
      return null; // Return null if queueInfo or queueInfo.status is undefined
    }
  
    switch (queueInfo.status) {
      case 'active':
        return <WaitingScreen business_info={businessInfo} customer_info={queueInfo} setShowModal={setShowModal}/>;
      
      case 'entered':
        return <EntryScreen business_info={businessInfo} customer_info={queueInfo}/>;
      
      case 'inactive':
        return <CancelledScreen business_info={businessInfo} customer_info={queueInfo}/>;
      
      default:
        return null; // Return null for any unexpected status
    }
  };

  return (
    <div className="customer-tracking-page">
  {loading ? (
    <div className="loader">Loading...</div>
  ) : (
    <>
      {/* {deleteMessage && <p className="delete-message">{deleteMessage}</p>} */}
      
      {tableready && queueInfo.status == 'active'  ? (
        renderTableReady()
      ) : (
        renderQueueInfo()
      )}

      {showModal && (
        <ConfirmDeleteModal
          onClose={() => setShowModal(false)}
          onConfirm={handleDelete}
          userType="user"
        />
      )}
    </>
  )}
</div>
  );
};

export default CustomerTrackingPage;
