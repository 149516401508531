import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
    apiKey: "AIzaSyAT4BT5A3ftRg4ME2PbCrV8jmi4m2B4BgU",
    authDomain: "waitwhiz-aad2c.firebaseapp.com",
    projectId: "waitwhiz-aad2c",
    storageBucket: "waitwhiz-aad2c.appspot.com",
    messagingSenderId: "818468329495",
    appId: "1:818468329495:web:1507b78256fa4e217f6452",
    measurementId: "G-MMCLP9GLSS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);