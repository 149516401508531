import React from 'react';
import './Message.css'; // Import the CSS file for styling

const Message = ({ notification, onClick }) => {
  return (
    <div className="message-container" onClick={onClick}>
     
      <div className="message-body">{notification.body}</div>

    </div>
  );
};

export default Message;