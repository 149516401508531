import React from 'react';
import './InvitedScreen.css';

const InvitedScreen= ({business_info, customer_info, setShowModal}) => {
  	
	//  (
    		// <div className="iphone-14-15-pro-11">
      		// 	<div className="iphone-14-15-pro-11-child" />
      		// 	<img className="waitwhiz-logo-pure-white-backg-icon" alt="" src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" />
      		// 	<img className="comorin-logo-1" alt="" src={business_info.business_metadata.logo_url} />
      		// 	<b className="hi-harman">Hi {customer_info.customer_name},</b>
      		// 	<b className="you-have-been">You have been invited</b>
      		// 	<div className="please-visit-the">Please visit the reception</div>
      		// 	<div className="button">
        	// 			<div className="star" />
			// 			<button onClick={() => setShowModal(true)} className='cancel-queue'>
			// 			</button>
        	// 			<div className="x" />
      		// 	</div>
      		// 	<img className="image-2-icon" alt="" src="image 2.png" />
    		// </div>);

			return (
				<div className="iphone-14-15-pro-10">
				<div className="frame-parent">
				<div className="waitwhiz-logo-pure-white-backg-parent">
				<img className="waitwhiz-logo-pure-white-backg-icon" alt="" src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" />
				<img className="comorin-logo-1" alt="" src={business_info.business_metadata.logo_url} />
				</div>
				<div className="frame-group">
				<div className="hi-harman-parent">
				<b className="hi-harman">Hi {customer_info.customer_name},</b>
				<b className="hi-harman">Your table is ready</b>
				</div>
				<div className="image-2-wrapper">
				<img className="image-2-icon" alt="" src="https://s3-alpha-sig.figma.com/img/531b/a8fa/005eaa5dff9edf9ff8e0d635e8e7b44d?Expires=1726444800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ir0stpq4h9EZ1qcMF4SonVgYcCMAk6mSZqXKBMNbsvYh9Bi7zE0nqaV74tI4Xmn62A2VPAlnEomVTQbK7DRLWoKy4E898V8uPJz0DL~mM96P31KfgFJmsGFztzAOKC3lWnUe5jAcKSExi1utQhVUS5Z2pUEVO3cmN4mKA9sR0olYI~sVV4kP3bLubBtPHa8iwvgDZOcarZZqX24BkpqK2-uNH5r8p8DtvLaXkWwns136OU~0QhBHpYrV10FxOqigq2HRNFEtddx7aU~2XRJVR62TB3NJhZx8lXaIjX8ndMen9LGVJLHPXlK15HF~VhjYk8f1UEoKX2Ttqk43Zz-o8Q__" />
				</div>
				<div className="please-visit-the-reception-wrapper">
				<div className="please-visit-the">Please visit the reception</div>
				</div>
				</div>
				</div>
				<div className="button">
					<div className="star" />
					<div 
						className="cancel-queue" 
						onClick={() => setShowModal(true)}
					>
						Cancel Queue
					</div>
					<div className="star" />
					</div>
				</div>);
};

export default InvitedScreen;
